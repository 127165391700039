import {} from 'react';
import { useState } from 'react';
import { useEffect } from 'react';

export default function useWindowSize() {
	const [windowSize, setWindowSize] = useState(getSize());

	useEffect(() => {
		function handleResize() {
			setWindowSize(getSize());
		}
		window.addEventListener('resize', handleResize);

		return () => window.removeEventListener('resize', handleResize);
	}, []);

	return windowSize;
}

function getSize() {
	return {
		width: window.innerWidth,
		height: window.innerHeight,
	};
}
